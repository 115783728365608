import React from 'react';

const TwitterFollow = () => {
  return (
    <>
      <a class="twitter-follow-button" href="https://twitter.com/scalablecss" data-size="large" data-show-count="false">Follow @ScalableCSS</a>
      <script async src="https://platform.twitter.com/widgets.js" charset="utf-8"></script>
    </>
  )
};

export default TwitterFollow;