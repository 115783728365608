import React from 'react';
import styled from 'styled-components'
import Button from './Button'

const Wrapper = styled.div`
    display: none;
    min-width: 20rem;
    padding-left: 3rem;

    @media (min-width: 1080px) { 
      display: block;
    }
`

const Sidebar = ({cta}) => {
  let form;
  switch (cta) {
    case 'styled-components-cheat-sheet':
      form = {
        title: 'Want to master Styled Components in React?',
        subTitle: `Levelling up your React and CSS skills in 2021 is a great idea. Here's why:`,
        bullet1: `Increase your employability and value as a React developer`,
        bullet2: `Learn how to style your React apps in a clean, efficient method`,
        bullet3: `Get a deep understanding of a CSS-in-JS library`,
        buttonText: 'View recommended course on Udemy',
        link: 'https://click.linksynergy.com/link?id=Wudf0pRqSy8&offerid=507388.1797176&type=2&murl=https%3A%2F%2Fwww.udemy.com%2Fcourse%2Freact-styled-components%2F'
      };
      break;
    default:
      form = {
        title: 'Want to become an advanced CSS & Sass developer?',
        subTitle: `Levelling up your CSS & Sass skills in 2021 is a great idea. Here's why:`,
        bullet1: `Increase your employability and value as a front-end developer`,
        bullet2: `Learn how to style your websites and applications in clean, efficient code `,
        bullet3: `Use tooling and techniques like BEM, flexbox, grid & more to improve your code`,
        buttonText: 'View recommended course on Udemy',
        link: 'https://click.linksynergy.com/link?id=Wudf0pRqSy8&offerid=507388.1026604&type=2&murl=https%3A%2F%2Fwww.udemy.com%2Fcourse%2Fadvanced-css-and-sass%2F'
      };
  }
  return (
    <Wrapper>
      <h3 style={{
        margin: `0 0 0.8rem 0`,
        fontSize: `1.2rem`
      }}>
        {form.title}</h3>
      <p style={{
        fontSize: `0.8rem`,
        marginBottom: `0.6rem`
      }}>{form.subTitle}</p>
      <ul style={{
        fontSize: `0.8rem`,
        marginBottom: `1rem`
      }}>
        <li style={{
        marginBottom: `0.2rem`,
      }}>{form.bullet1}</li>
        <li style={{
        marginBottom: `0.2rem`,
      }}>{form.bullet2}</li>
        <li style={{
        marginBottom: `0.2rem`,
      }}>{form.bullet3}</li>
      </ul>
      <Button link={form.link} text={form.buttonText} type="text" />
    </Wrapper>
  )
}

export default Sidebar;