import React, { useState } from 'react'
import { Link, graphql } from 'gatsby'
import { MDXRenderer } from 'gatsby-plugin-mdx'
import styled from 'styled-components'
import ModalContext from '../hooks/modalContext';

import Bio from '../components/Bio'
import Layout from '../components/Layout'
import SEO from '../components/seo'
import CTA from '../components/CTA'
import Sidebar from '../components/Sidebar'
import Modal from '../components/Modal'
import TwitterFollow from '../components/TwitterFollow'
import { rhythm, scale } from '../utils/typography'

const Wrapper = styled.div`
    padding: 1rem;
    max-width: 1080px;
    margin: 0px auto;

    @media (min-width: 1080px) { 
      display: flex;
      padding: 2rem 1rem;
    }
`

const BlogPostTemplate = (props) => {
    const post = props.data.mdx
    const siteTitle = props.data.site.siteMetadata.title
    const [modal, setModal] = useState(false);

    return (
      <>
        <SEO title={post.frontmatter.title} description={post.frontmatter.description || post.excerpt} />
        <Layout location={props.location} title={siteTitle}>
          <Wrapper>
            <div style={{
                    maxWidth: `720px`,
                    margin: `0px auto`
                  }}> 
              <ModalContext.Provider value={{ modal, setModal }}>
                <h1>{post.frontmatter.title}</h1>
                <p
                  style={{
                    ...scale(-1 / 5),
                    display: `block`,
                    marginBottom: rhythm(1),
                    marginTop: rhythm(-.5),
                  }}
                >
                  {post.frontmatter.date}
                </p>
                <MDXRenderer>{post.body}</MDXRenderer>    
                {post.frontmatter.type === 'success' ? null : <CTA cta={post.frontmatter.cta} />}
                {post.frontmatter.type === 'success' ? <TwitterFollow /> : null }
                <Bio />
                {post.frontmatter.type === 'success' || 'lead-magnet' ? null : <CTA cta={post.frontmatter.cta} />}
                <Modal>
                  <CTA cta={post.frontmatter.cta} />
                </Modal>
              </ModalContext.Provider>
            </div>
            <Sidebar cta={post.frontmatter.cta} />
          </Wrapper>
        </Layout>
      </>
    )
}

export default BlogPostTemplate

export const pageQuery = graphql`
  query($slug: String!) {
    site {
      siteMetadata {
        title
        author
      }
    }
    mdx(fields: { slug: { eq: $slug } }) {
      id
      excerpt(pruneLength: 160)
      frontmatter {
        title
        description
        date(formatString: "MMMM DD, YYYY")
        cta
        type
      }
      body
    }
  }
`
