import React, { useContext } from 'react';
import styled from 'styled-components';
import ModalContext from '../hooks/modalContext';

const ModalWrapper = styled.div`
  width: 100%;
  height: 100vh;
  background: rgba(0, 0, 0, 0.4);
  position: fixed;
  top: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
`

const Modal = ({ children }) => {
  const { modal, setModal } = useContext(ModalContext);

  const handleModalClick = (e) => {
    if (e.target.id === 'wrapper') {
      setModal(false);
    }
  }
  
  if (modal) {
    return (
      <ModalWrapper onClick={handleModalClick} id="wrapper">
        {children}
      </ModalWrapper>
    )
  } else {
    return null
  }
}

export default Modal;